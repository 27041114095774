import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import {
  ApiError,
  GreetieService,
  RedeemVoucherRequestDto,
  VoucherDto,
  VoucherService,
} from 'src/api';
import { ErrorDetails } from 'src/types/ErrorDetails';

import { updateVoucher as voucherUpdateVoucher } from './voucherSlice';

export interface RedeemState {
  loading: boolean;
  redeeming: boolean;
  hasError: boolean;
  error?: string;
  vouchers: VoucherDto[];
  selectedVoucher: VoucherDto | null;
}

const initialState: RedeemState = {
  loading: false,
  redeeming: false,
  hasError: false,
  error: '',
  vouchers: [],
  selectedVoucher: null,
};

export const redeemSlice = createSlice({
  name: 'redeem',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.hasError = false;
        state.error = '';
      }
      state.loading = action.payload;
    },
    redeeming: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.hasError = false;
        state.error = '';
      }

      state.redeeming = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setVouchers: (state, action: PayloadAction<VoucherDto[]>) => {
      state.vouchers = action.payload;
    },
    updateVoucher: (state, action: PayloadAction<VoucherDto>) => {
      const updatedVoucher = { ...action.payload };
      state.selectedVoucher = updatedVoucher;

      state.vouchers = state.vouchers.map((voucher) =>
        voucher.id === action.payload.id ? updatedVoucher : voucher
      );
    },
    setSelectedVoucher: (state, action: PayloadAction<VoucherDto>) => {
      state.selectedVoucher = action.payload;
    },
  },
});

export const { loading, redeeming, hasError, setVouchers, updateVoucher, setSelectedVoucher } =
  redeemSlice.actions;

export const getVouchersAsync =
  (greetieId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));

      const response = await GreetieService.getApiGreetieVouchers(greetieId);
      dispatch(setVouchers(response));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError(((e as ApiError).body as ErrorDetails).message));
    }
  };

export const getVoucherAsync =
  (voucherId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await VoucherService.getApiVoucher1(voucherId);
      dispatch(setSelectedVoucher(response));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError(((e as ApiError).body as ErrorDetails).message));
    }
  };

export const redeemVoucherAsync =
  (voucherId: string, merchantCode: string): AppThunk =>
  async (dispatch) => {
    try {
      if(merchantCode === "")
        return false;
      
      const request = {
        voucherId,
        merchantCode,
      } as RedeemVoucherRequestDto;

      console.log(request);

      dispatch(redeeming(true));
      const result = await VoucherService.postApiVoucherRedeem(request);
      dispatch(updateVoucher(result));
      dispatch(voucherUpdateVoucher(result));
      return true;
    } catch (e) {
      dispatch(hasError((e as ApiError).body));
      return false;
    } finally {
      dispatch(redeeming(false));
    }
  };

export const redeemCodeVoucherAsync =
  (voucherId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(redeeming(true));
      const result = await VoucherService.postApiVoucherRedeemed(voucherId);
      dispatch(updateVoucher(result));
      dispatch(voucherUpdateVoucher(result));
      return true;
    } catch (e) {
      dispatch(hasError((e as ApiError).body));
      return false;
    } finally {
      dispatch(redeeming(false));
    }
  };

export const redeemStateSelector = (state: RootState): RedeemState => state.redeem;

export default redeemSlice.reducer;
