/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MobileLoginRequestDto } from '../models/MobileLoginRequestDto';
import type { MobileUserDto } from '../models/MobileUserDto';
import type { MobileVerificationRequestDto } from '../models/MobileVerificationRequestDto';
import type { MobileVerificationResultDto } from '../models/MobileVerificationResultDto';
import type { TokenResponseDto } from '../models/TokenResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthService {
    /**
     * @param requestBody
     * @returns MobileVerificationResultDto OK
     * @throws ApiError
     */
    public static postApiAuthVerificationcode(
        requestBody?: MobileVerificationRequestDto,
    ): CancelablePromise<MobileVerificationResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/verificationcode',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns TokenResponseDto OK
     * @throws ApiError
     */
    public static postApiAuthLogin(
        requestBody?: MobileLoginRequestDto,
    ): CancelablePromise<TokenResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns MobileUserDto OK
     * @throws ApiError
     */
    public static getApiAuthMy(): CancelablePromise<MobileUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/my',
        });
    }
    /**
     * @param token
     * @param refreshToken
     * @returns TokenResponseDto OK
     * @throws ApiError
     */
    public static postApiAuthRefresh(
        token?: string,
        refreshToken?: string,
    ): CancelablePromise<TokenResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/refresh',
            query: {
                'token': token,
                'refreshToken': refreshToken,
            },
        });
    }
}
