import { useEffect, useReducer, useCallback, useMemo } from 'react';
//
import { AuthService, MobileUserDto } from 'src/api';
import { AuthStateType } from 'src/auth/types';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';


enum AuthTypes {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

interface AuthActionType {
  type: AuthTypes;
  payload: MobileUserDto | null;
}

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: AuthActionType) => {
  if (action.type === AuthTypes.INITIAL) {
    return {
      loading: false,
      user: action.payload,
    };
  }
  if (action.type === AuthTypes.LOGIN) {
    return {
      ...state,
      user: action.payload,
    };
  }
  if (action.type === AuthTypes.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const user = await AuthService.getApiAuthMy();

        dispatch({
          type: AuthTypes.INITIAL,
          payload: {
            ...user,
          },
        });
      } else {
        dispatch({
          type: AuthTypes.INITIAL,
          payload: null,
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: AuthTypes.INITIAL,
        payload: null,
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (mobileToken: string, code: string) => {
    const data = {
      token: mobileToken,
      code,
      deviceId: "00000000-0000-0000-0000-000000000000"
    };

    const token  = await AuthService.postApiAuthLogin(data);
    console.log(token!.token!)
    setSession(token!.token!);

    const user = await AuthService.getApiAuthMy();

    dispatch({
      type: AuthTypes.LOGIN,
      payload: {
        ...user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: AuthTypes.LOGOUT,
      payload: null,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
    }),
    [login, logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
