/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RedeemVoucherRequestDto } from '../models/RedeemVoucherRequestDto';
import type { VoucherDto } from '../models/VoucherDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class VoucherService {
    /**
     * @returns VoucherDto OK
     * @throws ApiError
     */
    public static getApiVoucher(): CancelablePromise<Array<VoucherDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/voucher',
        });
    }
    /**
     * @param id
     * @returns VoucherDto OK
     * @throws ApiError
     */
    public static getApiVoucher1(
        id: string,
    ): CancelablePromise<VoucherDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/voucher/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns VoucherDto OK
     * @throws ApiError
     */
    public static postApiVoucherRedeem(
        requestBody?: RedeemVoucherRequestDto,
    ): CancelablePromise<VoucherDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/voucher/redeem',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns VoucherDto OK
     * @throws ApiError
     */
    public static postApiVoucherRedeemed(
        id?: string,
    ): CancelablePromise<VoucherDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/voucher/redeemed',
            query: {
                'id': id,
            },
        });
    }
}
