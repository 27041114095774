// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  root: '/',
  start: '/start',
  auth: {
      login: `${ROOTS.AUTH}/jwt/login`,
    
  },
  my: (id: string) => `/my/${id}`,
  redeem: {
    root: (greetieId: string, voucherId?: string) => `/redeem/${greetieId}?${(voucherId !== undefined? voucherId: '')}`, 
    voucher: (id: string) => `/redeem/voucher/${id}`,
  },
  voucher: {
    root: () => `/voucher`, 
    details: (id: string) => `/voucher/${id}`,
  },
  greetie: {
    root: `/greetie`, 
    new: `/greetie/new`, 
    view: (id: string) => `/greetie/view/${id}`,
    viewById: (id: string) => `/greetie/view/${id}?byId=true`,
    details: (id: string) => `/greetie/details/${id}`,
  },
  merchant: {
    root: `/merchant`,
    details: (id: string) => `/merchant/details/${id}`,
  },
  

};
