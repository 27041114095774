import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/auth/guard';

// layouts
import StartLayout from 'src/layouts/start';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
export const GreetiePage = lazy(() => import('src/pages/greetie/list'));
export const GreetieDetailsPage = lazy(() => import('src/pages/greetie/details'));
export const GreetieNewPage = lazy(() => import('src/pages/greetie/new'));
export const GreetieViewPage = lazy(() => import('src/pages/greetie/view'));
export const RedeemPage = lazy(() => import('src/pages/redeem/list'));
export const RedeemVoucherRedeemPage = lazy(() => import('src/pages/redeem/redeem'));
export const VoucherPage = lazy(() => import('src/pages/voucher/list'));
export const VoucherDetailsPage = lazy(() => import('src/pages/voucher/details'));
export const MyGreetiesPage = lazy(() => import('src/pages/my'));

const Page404 = lazy(() => import('src/pages/404'));

// ----------------------------------------------------------------------
// MERCHANT
const MerchantListPage = lazy(() => import('src/pages/merchant/list'));
const MerchantDetailsPage = lazy(() => import('src/pages/merchant/details'));

export const publicRoutes = [
  {
    element: (
      <GuestGuard>
        <StartLayout>
          <Outlet />
        </StartLayout>
      </GuestGuard>
    ),
    children: [
      {
        path: '/', element: <HomePage />
      },
      {
        path: 'merchant',
        children: [
          { element: <MerchantListPage />, index: true },
          { path: 'list', element: <MerchantListPage /> },
          { path: 'details/:id', element: <MerchantDetailsPage /> },
        ],
      },
      { path: '404', element: <Page404 /> },
    ],
  },
];
