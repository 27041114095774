// @mui
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { useAuthContext } from 'src/auth/hooks';
import { useResponsive } from 'src/hooks/use-responsive';
import { navAuthenticatedConfig, navConfig } from '../start/config-navigation';

import NavMobile from '../_common/nav/mobile';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function CompactLayout({ children }: Props) {
  const mdUp = useResponsive('up', 'md');
  const { user } = useAuthContext();

  return (
    <>
      <Container
        component="main"
        maxWidth={false}
        sx={{
          position:"relative",
          minHeight: '100vh',
          '&.MuiContainer-root': { p: 0 },
        }}
      >
        <Box sx={{ position: 'absolute', top: 10, left: 10, zIndex:10000 }}>
          <NavMobile offsetTop={false} data={user ? navAuthenticatedConfig : navConfig} />
        </Box>

        <Stack
          sx={{
            // m: 'auto',
            // minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  );
}
