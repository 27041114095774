
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
// i18n
import 'src/locales/i18n';
// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
import ReactGA from 'react-ga4';
// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
// auth
import { AuthConsumer, AuthProvider } from './auth/context/jwt';
import SnackbarProvider from './components/snackbar/snackbar-provider';


// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider>
        <ThemeProvider>
          <MotionLazy>
            <SnackbarProvider>
              <ProgressBar />
              <AuthConsumer>
                <Router />
              </AuthConsumer>
            </SnackbarProvider>
          </MotionLazy>
        </ThemeProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
