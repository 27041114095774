import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  createListenerMiddleware,
} from '@reduxjs/toolkit';

import commonReducer from './slices/commonSlice';
import greetieReducer from './slices/greetieSlice';
import userReducer from './slices/userSlice'
import redeemReducer from './slices/redeemSlice'
import voucherReducer from './slices/voucherSlice'
import merchantReducer from './slices/merchantSlice';

const combinedReducer = combineReducers({
  common: commonReducer,
  greetie: greetieReducer,
  user: userReducer,
  redeem: redeemReducer,
  voucher: voucherReducer,
  merchant: merchantReducer,
});

const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, any, Action<string>>;

const createBrowserHistory = require('history').createBrowserHistory;

const history = createBrowserHistory();
export { history };
