// @mui
import Box from '@mui/material/Box';
//
import { bgGradient } from 'src/theme/css';
import { HeaderSimple as Header } from '../_common';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthModernCompactLayout({ children }: Props) {
  return (
    <Box
      sx={{
        ...bgGradient({
          direction: '135deg',
          endColor: '#964AE2',
          startColor: '#47C8D3',
        }),
      }}
    >
      <Header />

      <Box
        component="main"
        sx={{
          py: 12,
          display: 'flex',
          minHeight: '100vh',
          textAlign: 'center',
          px: { xs: 2, md: 0 },
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            py: 5,
            px: 3,
            maxWidth: 420,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
