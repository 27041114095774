// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hooks';

// theme
//
import Header from './header';

import Main from './main';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {

  const pathname = usePathname();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 1,
      }}
    >
      <Header />
      <Main>{children}</Main>
    </Box>
  );
}
