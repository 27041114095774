// routes
import { paths } from 'src/routes/paths';

// components
import Iconify from 'src/components/iconify';



// ----------------------------------------------------------------------


export const navConfig = [
  {
    title: 'Mina greeties',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: paths.greetie.root,
  },
  {
    title: 'Mina gåvor',
    icon: <Iconify icon="material-symbols:featured-seasonal-and-gifts-rounded" />,
    path: paths.voucher.root(),
  },
];
