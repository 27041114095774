// routes
import { paths } from 'src/routes/paths';

// components
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  
  <SvgColor src={`/assets/images/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

export const navAuthenticatedConfig = [
  {
    title: 'Mina greeties',
    icon: <SvgColor src='/assets/images/vit-kanin.svg' color="#000000" sx={{ width: 20, height: 20 }} />,
    path: paths.greetie.root,
  },
  {
    title: 'Mina gåvor',
    icon: <Iconify icon="material-symbols:featured-seasonal-and-gifts-rounded" />,
    path: paths.voucher.root(),
  },
];

export const navConfig = [
  {
    title: 'Handlare',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: paths.merchant.root,
  },
  {
    title: 'Hur fungerar det?',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: 'https://www.greetie.app/hur-fungerar-det',
  },
  {
    title: 'Om greetie',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: 'https://www.greetie.app/om-greetie',
  },
];
