import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import {  GreetieMerchantDto, MerchantService } from 'src/api';

export interface MerchantState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  merchants: GreetieMerchantDto[];
}

const initialState: MerchantState = {
  merchants: [],
  loading: false,
  hasError: false,
  error: '',
};

export const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setMerchants: (state, action: PayloadAction<GreetieMerchantDto[]>) => {
      state.merchants = action.payload;
    },

  },
});

export const {
  loading,
  hasError,
  setMerchants,
} = merchantSlice.actions;

export const getMerchantsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const data = await MerchantService.getApiMerchant();
    dispatch(setMerchants(data));
    dispatch(loading(false));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const merchantStateSelector = (state: RootState): MerchantState => state.merchant;

export default merchantSlice.reducer;
