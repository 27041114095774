import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';

// layouts
import CompactLayout from 'src/layouts/compact/layout';

// ----------------------------------------------------------------------
export const GreetieViewPage = lazy(() => import('src/pages/greetie/view'));

// ----------------------------------------------------------------------

export const compactRoutes = [
  {
    element: (
      <AuthGuard>
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'greetie',
        children: [{ path: 'view/:id', element: <GreetieViewPage /> }],
      },
    ],
  },
];
