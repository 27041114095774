/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OverLayItemType {
    EMOJI = 'emoji',
    LOTTIE = 'lottie',
    TEXT = 'text',
    STICKER = 'sticker',
    PICTURE = 'picture',
}
