import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
//

import { Provider } from 'react-redux';
import { store } from 'src/store';
import App from './App';
import { OpenAPI } from './api';

if(process.env.NODE_ENV !== 'development')
{
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS!);
}

if(process.env.NODE_ENV === 'development')
  {
    OpenAPI.HEADERS = {"ngrok-skip-browser-warning": "true"}
  }

// ----------------------------------------------------------------------
OpenAPI.BASE = process.env.REACT_APP_BACKEND_OPENAPI_BASE_URL ?? '';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
);
