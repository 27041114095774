// @mui
import  { BoxProps } from '@mui/material/Box';
import { Container } from '@mui/material';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';

//
import { HEADER} from '../config-layout';

// ----------------------------------------------------------------------


export default function Main({ children, sx, ...other }: BoxProps) {
  const lgUp = useResponsive('up', 'lg');

  return (
    <Container
      maxWidth="lg"
      component="main"
      sx={{
        '&.MuiContainer-root': { p: 0 },
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        pt: `${HEADER.H_MOBILE + 30}px`,
        pb: 10,
        ...(lgUp && {
          pt: `${HEADER.H_MOBILE * 2 + 10}px`,
          pb: 15,
        }),
      }}
    >
      {children}
    </Container>
  );
}
