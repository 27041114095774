import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GreetieInfoDto, MobileUserDto, MobileUserService, VoucherDto } from 'src/api';
import type { AppThunk, RootState } from 'src/store/index';

import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';

export interface UserState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  user: MobileUserDto | null;
  sentGreeties: GreetieInfoDto[] | null;
  recievedGreeties: GreetieInfoDto[] | null;
  vouchers: VoucherDto[] | null;
}

const initialState: UserState = {
  loading: false,
  hasError: false,
  error: '',
  user: null,
  sentGreeties: null,
  recievedGreeties: null,
  vouchers: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setUser: (state, action: PayloadAction<MobileUserDto>) => {
      state.user = action.payload;
    },
    setSentGreeties: (state, action: PayloadAction<GreetieInfoDto[]>) => {
      state.sentGreeties = action.payload;
    },
    setRecievedGreeties: (state, action: PayloadAction<GreetieInfoDto[]>) => {
      state.recievedGreeties = action.payload;
    },
    setVouchers: (state, action: PayloadAction<VoucherDto[]>) => {
      state.vouchers = action.payload;
    },
  },
});

export const { loading, hasError, setUser, setSentGreeties, setRecievedGreeties, setVouchers } =
  userSlice.actions;

export const getUserAsync =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const data = await MobileUserService.getApiMobileuser(userId);
      dispatch(setUser(data));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const getSentGreetiesAsync =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const response = await MobileUserService.getApiMobileuserSentgreeties();
      dispatch(setSentGreeties(response));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const getRecievedGreetiesAsync =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const response = await MobileUserService.getApiMobileuserReceivedgreeties();
      dispatch(setRecievedGreeties(response));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

  export const getVouchersAsync =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const response = await MobileUserService.getApiMobileuserVouchers();
      dispatch(setVouchers(response));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const userStateSelector = (state: RootState): UserState => state.user;

export default userSlice.reducer;
