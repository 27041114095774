/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GreetieMerchantDto } from '../models/GreetieMerchantDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MerchantService {
    /**
     * @returns GreetieMerchantDto OK
     * @throws ApiError
     */
    public static getApiMerchant(): CancelablePromise<Array<GreetieMerchantDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/merchant',
        });
    }
    /**
     * @returns GreetieMerchantDto OK
     * @throws ApiError
     */
    public static getApiMerchantActive(): CancelablePromise<Array<GreetieMerchantDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/merchant/active',
        });
    }
}
