// @mui
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
// theme
import { bgGradient } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useAuthContext } from 'src/auth/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import Logo from 'src/components/logo';
// components
import { GreetieLogo } from 'src/assets/icons/greetie-logo';
import { navAuthenticatedConfig } from '../start/config-navigation';
//
import { HEADER } from '../config-layout';
import { AccountPopover } from '../_common';
import { navConfig } from './config-navigation';
import LoginButton from '../_common/login-button';
import NavMobile from '../_common/nav/mobile';
import NavDesktop from '../_common/nav/desktop';



// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header() {
  const { user } = useAuthContext();
  const mdUp = useResponsive('up', 'md');
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  const nav = useBoolean();
  const router = useRouter();

  const renderContent = (
    <>
      {!mdUp && (
        <>
          <NavMobile offsetTop={offsetTop} data={user ? navAuthenticatedConfig : navConfig} />
          <Box
            sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Logo />
          </Box>
        </>
      )}
      {mdUp && (
        <>
          <Logo />
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
            <NavDesktop offsetTop={offsetTop} data={user ? navAuthenticatedConfig : navConfig} />
          </Box>
        </>
      )}

      <Box sx={{ flexGrow: 0 }}>
        {user ? (
          <AccountPopover />
        ) : (
          <LoginButton sx={{ backgroundColor: '#f3df67', color: '#000000', borderColor: '#fff' }} />
        )}
      </Box>
    </>
  );

  return (
    <>
      <AppBar
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: { xs: HEADER.H_MOBILE, md: HEADER.H_DESKTOP },
          ...bgGradient({
            direction: 'to right',
            endColor: '#964AE2',
            startColor: '#47C8D3',
          }),
        }}
      >
        <Toolbar sx={{ border: 0 }}>{renderContent}</Toolbar>
      </AppBar>

      <Toolbar sx={{ mb: { xs: '0px', md: '30px' } }} />
    </>
  );
}
