// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import HeaderStart from './header';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function StartLayout({ children }: Props) {
  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={
        {
          // minHeight: '100vh'
        }
      }
    >
      <HeaderStart />
      <Stack
        sx={{
          // m: 'auto',
          // minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </Stack>
    </Container>
  );
}
